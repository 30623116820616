$bgColor: (#115293, #9a0036, #d32f2f, #f57c00, #1976d2, #388e3c,#b2a300);
.configuration {
    &__content {
      @for $i from 1 through length($bgColor) {
        $color : nth($bgColor, $i);
        &:nth-child(#{$i}) {
          .configuration__content--title {
            background-color: $color;
          }
        }
      }
      &--body>div:nth-child(1) {
        min-height: 220px;
      }
    }
    &__input {
        &--false {
            border-color:  red !important;
        }
    }
  }