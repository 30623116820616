@import "./assets/css/variable.scss";
button {
    outline: none !important;
}
.login-footer .content-footer {
    width: 100%;
    position: $position-fixed;
    background-color: rgb(236, 236, 236);
    min-height: 70px;
    height: 70px;
    color: $color-01;
}

.login-header {
    width: 100%;
    height: 90px;
}

.login-footer {
    width: 100%;
    height: 70px;
}

.login-header .content-header {
    height: 90px;
    background-color: $background-blue-color;
    color: $color-white;
    position: $position-fixed;
    width: 100%;
    min-height: 90px;
    padding: 0px;
    display: $display-flex;
}

.login-header .content-header .left-header-content,
.login-header .content-header .right-header-content {
    display: $display-flex;
    width: 100%;
    height: 100%;
}

.login-header .content-header img {
    width: 80px;
    height: 71px;
    margin: $margin-auto;
    margin-right: 10px;
}

.login-header .content-header h3 {
    margin: $margin-auto;
    margin-left: 0px;
}

.header-content-guide {
    margin: $margin-auto;
    padding-right: 10px;
    border-right-width: 1px;
    margin-right: 10px;
    border-right: inset;
}

.header-content-contact {
    margin: $margin-auto;
    margin-left: 0px;
}

.login-content {
    width: 100%;
    height: calc(100vh - 160px);
    display: $display-flex;
    justify-content: flex-end;
}

.login-content-body {
    max-width: 400px;
    min-width: 400px;
    margin: auto 16%;
    img {
        width: 100%;
        height: auto;
    }
    @media (max-width: 1140px) {
        margin:  auto;
    }
    & > div {
        padding: 20px;
        margin-top: 30px;
        box-shadow: rgba(117, 117, 117, 0.1) 0px 1px 4px 0px;
        background: white;
        border-radius: 15px;
    }
}

.login-content p {
    text-align: center;
}

.content-footer {
    width: 100%;
    height: 100%;
    display: $display-flex;
    padding: 15px 0px;
}

.auth-box {
    background: $color-white;
    padding: 20px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 90%;
    margin: 10% 0;
}

.auth-box.on-sidebar {
    top: 0px;
    right: 0px;
    height: 100%;
    margin: 0px;
    position: $position-absolute;
}

.login-form {
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
    background: $color-white;
    padding: 20px;
    max-width: 400px;
    min-width: 400px;
    margin: $margin-auto;
    border-radius: 5px;
}

.login-btn {
    display: $display-flex;
    margin-bottom: 0px !important;
}

.login-btn .login-btn-text,
.btn-login {
    margin: $margin-auto;
}

.login-title {
    padding-bottom: 20px;
    margin: $margin-auto;
    color: $color-02;
    text-transform: uppercase;
    font-weight: bold;
}

.login-group-input {
    position: $position-relative;
    display: $display-flex;
}

.login-group-btn {
    position: $position-relative;
    display: $display-flex;
    margin-bottom: 0 !important;
}

.login-icon-user {
    align-items: center;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5rem !important;
    color: $color-grey;
    background-color: $background-color-01;
    border: 1px solid #d1d3e2;
    border-radius: 0.35rem 0rem 0rem 0.35rem;
}

.login-error {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: $color-red;
    padding-left: 40px;
}

.overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    background: $black-color-60;
    z-index: 9999;
    pointer-events: unset;
}

.loading-indicator {
    top: 45%;
    left: 45%;
    position: fixed;
}

/* header */
.color-icon {
    color: white;
  }
.show-sidebar {
    .left-sidebar {
        width: $sidebar-width-full !important;
        left: 0;
    }
}

  @media (max-width: 768px) {
    .left-sidebar {
      width: 0px !important;
      left: 0 !important;
    }
    .content-wrapper {
      margin-left: 0px !important;
    }
    .footer {
      margin-left: 0px !important;
    }
    .navbar-collapse {
      flex-basis: 0;
    }
    .navbar-nav {
      // width: 100%;
      display: flex;
      justify-content: flex-end;
      flex-direction: row;
      margin-top: -21px;
    }
    .navbar-header {
      display: contents;
    }
    .navbar-left-content {
      display: none;
    }
    .crud-buttons {
      text-align: left !important;
      margin-left: 12px !important;
    }
    .fa-desktop {
      display: none !important;
    }
    .left-header-content h3 {
      font-size: 16px;
    }
    .navbar-header-left {
        font-size: 16.5px;
    }
}
@media (max-width: 576px) {
    .left-sidebar {
      width: 0px !important;
      left: 0 !important;
    }
    .content-wrapper {
      margin-left: 0px !important;
    }
    .footer {
      margin-left: 0px !important;
    }
    .crud-buttons {
      text-align: left;
      margin-left: 12px;
    }
    .fa-desktop {
      display: none !important;
    }
    .left-header-content h3 {
      font-size: 11px !important;
    }
    .content-footer .col-lg-8 {
      font-size: 11px;
      margin-top: -10px;
    }
    .content-footer .col-lg-4 {
      font-size: 11px;
      margin-top: -12px;
    }
    .right-header-content .header-content-guide {
      font-size: 11px;
    }
    .right-header-content .header-content-contact {
      font-size: 11px;
    }
  }  
// @media (max-width: 992px) { 
//     .left-sidebar {
//       position: fixed;
//       width: $sidebar-width-full;
//       height: 100%;
//       top: 0px;
//       z-index: 20;
//       padding-top: 70px;
//       background: #fff;
//       box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
//     }
// }
.topbar {
    position: fixed;
    top: 0px;
    width: 100%;
    background: #273e52;
    z-index: 50;
    box-shadow: 5px 0px 10px rgba(0, 0, 0, 0.5);
}

.topbar .top-navbar {
    min-height: 70px;
    padding: 0px 15px;
}

.topbar .top-navbar .navbar-header-left {
    margin-right: 0px;
    padding-bottom: 0px;
    padding-top: 0px;
    display: flex;
    width: fit-content;

    i {
        margin: auto 10px auto auto;
        color: white;
    }

    p {
        margin: auto;
        color: white;
    }
}

.navbar-left-content {
    display: flex;

    i {
        margin: auto 10px auto auto;
        color: white !important;
    }

    p {
        margin: auto;
        color: white !important;
    }
}

.ml-auto.float-right.navbar-nav {
    .test-classname {
        color: white;
    }

    img {
        margin-right: 10px;
    }

    div {
        top: 100%;
        padding-top: 0px;
        border-top: 0px;
        margin-top: 0px;
    }
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
    color: white !important;
}

.topbar .top-navbar .navbar-header-right {
    display: flex;
    flex-direction: row;
    flex-basis: auto;
    flex-grow: 1;
    align-items: center;

    .navbar-nav {
        list-style: none;

        .nav-item.show {
            background: rgba(0, 0, 0, 0.05);
        }

        .nav-item.show > .scale-up {
            transform: scale(1);
            transform-origin: right 0px;
        }

        div {
            display: flex;
            padding: 0px 10px;

            i,
            p {
                margin: auto;
                color: white;
                padding-right: 10px;
            }
        }

        li {
            a {
                color: white !important;

                img {
                    width: 30px;
                    border-radius: 100%;
                    margin-right: 10px;
                }
            }
        }
    }
}

.error-text {
    color: red !important;
}

.fl-right {
    float: right;
}

.fn-w-600 {
    font-weight: 600;
}

.top-modal-header {
    background-color: #438eb9;
    color: white;
    height: 40px;
    align-items: center !important;

    h5 {
        font-size: 15px !important;
    }
}

.content-modal-body {
    padding: 10px 30px !important;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: calc(100vh - 145px);

    label {
        color: #303230;
        font-weight: 600;
    }
}
.modal-dialog {
    transform: translate(0, -50px) !important;
    max-height: calc(100vh - 100px);
    top: 22px;
}

.bg-color {
    background-color: #438eb9 !important;
}

/*Content body wrapper*/
.content-wrapper {
    margin-top: 70px;
    margin-left: $sidebar-width-full;
    min-height: 953px;
    padding-bottom: 100px;
    // background-color: #1b2b39;
}

.page-content {
    min-height: calc(100vh - 190px);
}

/* Sidebar*/
.left-sidebar {
    position: fixed;
    width: $sidebar-width-full;
    height: 100%;
    top: 0px;
    z-index: 20;
    padding-top: 70px;
    background: #212528;
    box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
}

/* Footer */
.footer {
    bottom: 0;
    color: #747d8a;
    left: 0px;
    padding: 17px 15px;
    position: absolute;
    right: 0;
    border-top: 1px solid rgba(120, 130, 140, 0.13);
    background: #fff;
    margin-left: 240px;
    min-height: 70px;
    display: flex;
    background-color: $background-color-02;

    .footer-left-content {
        display: flex;
        margin: auto;
    }

    .footer-right-content {
        display: flex;
        width: 50px;
        height: 50px;
        text-align: center;
        color: #fff;
        background: rgba(52, 58, 64, 0.5);
        line-height: 46px;
        border-radius: 2.25rem !important;
        margin-right: 7px;

        i {
            margin: auto;
        }
    }
}

/*Menu Management*/

.menu-list-content-page {
    padding: 0 !important;

    .pd-0 {
        padding: 0 !important;
    }
    .select-filter {
        margin-left: 10px !important;
    }
    .refresh-button {
        margin-left: 10px !important;
    }
}

.table {
    color: black;
    tbody tr {
        .inp-location {
            width: 45px;
        }
    }
}

.react-bs-table {
    .react-bs-container-header {
        table thead tr th {
            white-space: inherit !important;
            text-overflow: inherit !important;
            vertical-align: middle !important;
            .tb-checkbox-item {
                display: flex;
            }
        }
    }
    .react-bs-container-body {
        .fas-aqua {
            color: #438eb9;
        }
        table tbody tr td {
            vertical-align: middle;
        }
    }
}

.menu-button {
    position: absolute !important;
    right: 15px;

    .btn-success {
        margin-right: 10px;
        border-radius: 5px !important;
    }

    .btn-delete-row {
        border-radius: 5px !important;
    }
}

.notification-delete-modal {
    .modal-content {
        .modal-header {
            height: 40px;
            align-items: center;
            background-color: #438eb9;
            color: white;
        }
        .modal-body {
            min-height: 80px;
            color: black;
            font-size: 18px;
            display: flex;
            align-items: center;
        }
        .modal-footer {
            height: 60px;
            align-content: center;

            button:first-child {
                margin-right: 20px;
            }
        }
    }
}

// anouncement card
.list-item-header {
    padding: 15px !important;
    // height: 184px;
    background-color: #28a745 !important;
}

.card-footer-anouncement {
    margin-top: 5px;
    height: 80px;
    padding: 0px 20px 10px 20px;
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer-anouncement:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.emergency-notification {
    height: 100%;
}

.card-body-icon {
    position: absolute;
    z-index: 0;
    top: -1.25rem;
    right: -1rem;
    opacity: 0.4;
    font-size: 5rem;
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg);
}

.emergency-notification-item {
    height: 0;
}

.executive-direction-information {
    max-height: 320px;
    overflow-y: auto;
}

.fa-bar-chart:before {
    content: "\f080";
}

.text-emergency-color {
    color: #28a745;
}

.text-direction-color {
    color: #007bff;
}

.text-reflect-color {
    color: #dc3545;
}

.text-executive-direction-information {
    color: #17a2b8;
}

.text-executive-direction-information-size {
    height: 100%;
}
.emergency-color {
    background-color: #28a745 !important;
}

.direction-color {
    background-color: #007bff !important;
}

.reflect-color {
    background-color: #dc3545 !important;
}

.emergency-notification-body {
    height: 100%;
    overflow-y: auto;
}

.schedule-button {
    position: absolute !important;
    right: 15px;
}
.react-bootstrap-table-page-btns-ul {
    align-items: flex-end;
    justify-content: flex-end;
}

.schedule-management #pageDropDown {
    display: none;
}

.schedule-management .react-bootstrap-table-page-btns-ul {
    display: flex !important;
}

.refresh-search {
    display: flex;
}

.refresh-button {
    width: 45px;
}

.add-edit-form-schedules {
    max-width: 1000px !important;
    display: block;
    height: 100%;
    overflow-y: auto !important;
    max-height: 100%;
    margin: 0 auto;
    top: 50px;
}

.margin-attachment-label {
    margin: 0 12px;
}

.validate-ckeditor {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #e74a3b;
}

/* Add new menu */
.btn-icon-template {
    border-radius: 0px 0.35rem 0.35rem 0px !important;
    border-left-width: 0px !important;
}

.input-value-icon {
    border-right-width: 0px !important;
}

.popover-inner {
    min-width: 200px;

    .popover-body {
        min-height: 325px !important;
        // overflow-x: scroll !important;
        padding: 0.75rem 0.75rem !important;

        .select-customized {
            .css-yk16xz-control {
                display: none;
            }

            .css-26l3qy-menu {
                margin-bottom: 0px;
                margin-top: 0px;
                border-radius: 4px !important;
            }
        }
    }
}

.view-attachment {
    color: #4e73df;
    margin-block-end: 0;
}

.view-attachment:hover {
    text-decoration: underline;
}

.view-attachment-modal {
    max-width: 1000px !important;
}

.item-disabled {
    pointer-events: none;
}

// pagination
.page-item {
    cursor: pointer;
}
.page-item .page-link {
    color: #273e52 !important;
}
.page-item.active .page-link {
    background-color: #273e52 !important;
    border-color: #273e52 !important;
    color: white !important;
}

// required
.required::after {
    content: " (*)";
    color: red;
}

.cursor-pointer {
    cursor: pointer;
}
