.overview-card {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(23rem, 1fr));
  // grid-template-columns: repeat(4 , 1fr);
  grid-gap: 0.5rem;
  &-dashboard {
    grid-template-columns: repeat(3 , 1fr) ;
    @media(max-width: 1440px) { 
      grid-template-columns: repeat(2 , 1fr);
     }
     @media(max-width: 1068px) { 
      grid-template-columns: 1fr;
     }
  }
  //Start sreen dashboard
  &__item-chart {
    grid-column: 1 / 3;
    @media(max-width: 1440px) { 
      grid-column: unset;
     }
     &:hover {
      background-color: #f2f2f2 !important;
      .overview-card__item--body {
        background-color: #f2f2f2;
      }
    }
  }
  &__item-table {
    .overview-card__item--body {
      border-bottom: none;
    }
    .table {
      tbody {
        tr:nth-child(1){
          td {
            border-top: none;
          }
        }
      }
    }
    &:hover {
      background-color: #f2f2f2 !important;
      .overview-card__item--body {
        background-color: #f2f2f2;
      }
    }
   
  }
  //End sreen dashboard
  &__item {
    transition: .3s;
    background-color: #f2f2f2;
    &:hover {
        background-color: #e9ecef;
        .overview-card__item--body {
          border-top: 1px solid #e9ecef;
        }
        .overview-card__item--title {
          letter-spacing: .3px;
        }
    }
    
    &--title {
      padding: 15px 20px;
      background-color: #e9ecef;
      color: #273e52;
      pointer-events: none;
      h5 {
        margin: 0;
      }
    }
    &--body {
      position: relative;
      min-height: 150px;
      padding: 10px;
      display: flex;
      align-items: center;
      border: 1px solid #7a7c7f2d;
      white-space: nowrap;
      cursor: pointer;
      ul {
        flex: 1 1;
        display: block;
        list-style: none;
        margin: 0;
        padding: 0;
        overflow: hidden;

        li {
          display: flex;
          align-items: baseline;
          justify-content: space-between;
          line-height: 1.5;
          color: #000;
          &:nth-child(3) {
            div.state {
              &--on {
                color: #2eb35a;
              }
              &--off {
                color: red;
              }
              &::before {
                content: "";
                display: inline-block;
                width: 0.6rem;
                height: 0.6rem;
                background-color: currentColor;
                border-radius: 50%;
                margin-right: 0.5rem;
                margin-top: -0.2rem;
                vertical-align: middle;
              }
            }
          }
        }
        div {
          text-align: right;
          font-size: 15.5px;
        
        }
        div.truncate{
          white-space: nowrap; 
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    &--hidden {
      display: none;
    }
    &--connected {
      border: 1px solid rgba(81, 92, 71, 0.445) !important;
      background-color: #faf9f9;
      &:hover {
        background-color: #e9f1e8 ;
      }
    }
    &--disconnect {
      border: 1px solid #d22c32 !important;
      background-color: #fbebec;
      &:hover {
        background-color: #f6d5d6 ;
      }
    }
    &--error {
      background: #dddddd;
      &:hover {
        background-color: #dddddd ;
      }
    }
  }
}

#overlay {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 2;
  cursor: pointer;
}
#text{
  position: absolute;
  top: 0;
  left: 0;
  color: white;
}