.form-add {
    &__image {
        height: 80px;
        width: 80px;
    }
    &__button-wrapper {
        position: relative;
        width: 120px;
        text-align: center;
        span.label {
            position: relative;
            z-index: 0;
            display: inline-block;
            width: 100%;
            background: #17a2b8;
            cursor: pointer;
            color: #fff;
            padding: 7px 12px;
        }
        &:hover {
            span.label {
                background: #138496;
                transition: .25s
            } 
        }
        .upload-box {
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            width: 0;
            display: inline-block;
            cursor: pointer;
            z-index: 1;
        }
    }
}