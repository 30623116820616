.react-bs-table-container {

  .react-bs-table table th,
  .react-bs-table table td {
    overflow: initial;
    white-space: initial;
    text-overflow: initial;
  }
}

.sticky-table table th:first-child,
.sticky-table table td:first-child {
  position: -webkit-sticky;
  position: sticky;
  background-color: white;
  width: 50px;
  min-width: 50px;
  max-width: 50px;
  left: 0px;
  box-shadow: 2px 0px 6px -3px;
  border: none !important;
}

.sticky-table table th:nth-child(2),
.sticky-table table td:nth-child(2) {
  position: -webkit-sticky;
  position: sticky;
  background-color: white;
  left: 50px;
  box-shadow: 2px 0px 6px -3px;
  border: none !important;
}

.filter-tools {
  display: flex;
  flex-flow: wrap;
  margin-bottom: 20px;

  .item {
    margin-right: 10px;
    margin-bottom: 10px;
  }

  button {
    height: 37px;
  }
}

.crud-buttons {
  margin: 0;
  padding: 0;
  text-align: right;

  button {
    margin-bottom: 10px;
  }

  button:not(:last-child) {
    margin-right: 10px;
  }


}

.table-modal {
  max-width: 1000px;
  display: block;
  height: 100%;
  overflow: hidden;
  max-height: 100%;
  margin: 0 auto;
  top: 50px;

  .modal-content {
    height: 100%;

    form {
      height: 100%;

      .modal-body {
        height: calc(100% - 115px);
        overflow: hidden;
        padding: 0;

        .content {
          height: 100%;
          overflow: scroll;
        }
      }
    }
  }
}

.remove-modal {
  .modal-content {
    margin-top: 50%;
  }
}
.table-hover {
  tbody {
    tr.hidden-table {
      line-height: 1;
      &:hover {
        background-color: transparent;
      }
    }
  }
}