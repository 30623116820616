/*Dropdown selection*/
.select-item {
    margin-top: -8px;
    font-size: 1rem;
}

@font-face {
    font-family: "Material Icons";
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/materialicons/v36/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format("woff2");
}

.material-icons {
    font-family: "Material Icons";
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: "liga";
    -webkit-font-smoothing: antialiased;
}

/* mdl-dropbox is a custom classname to increases the specificity of our styles. It can be anything. 
   * The idea is that it is easy to extend/override builtin styles with very little effort.
   */

.mdl-dropbox .dropdown-trigger>span:after {
    font-size: 12px;
    color: #555;
}

.mdl-dropbox .toggle {
    font: normal normal normal 18px/1 "Material Icons";
    color: #555;
    white-space: pre;
    margin-right: 4px;
}

.mdl-dropbox .toggle.collapsed::after {
    cursor: pointer;
    content: "\E5CF" !important;
    vertical-align: middle;
}

.mdl-dropbox .toggle.expanded::after {
    cursor: pointer;
    content: "\E5CE" !important;
    vertical-align: middle;
}

/* checkbox styles */
.mdl-dropbox .checkbox-item {
    position: relative;
    width: 1rem;
    height: 1rem;
    margin-right: 0.75rem;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: 0;
    vertical-align: middle;
}

.mdl-dropbox .checkbox-item:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    border: 2px solid #aaa;
    transition: all 0.3s ease-in-out;
}

.mdl-dropbox .checkbox-item:checked:before {
    height: 50%;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    border-top-style: none;
    border-right-style: none;
    border-color: #2196f3;
}

.hide:not(.match-in-children) {
    display: none;
}

.react-dropdown-tree-select .dropdown {
    position: relative;
    display: table;
    width: 100%;

    .dropdown-trigger {
        padding: 4px;
        line-height: 20px;
        max-height: 200px;
        display: inline-block;
        overflow: auto;
        border: 1px solid  #ccc;
        border-radius: 4px;
        width: 100%;
        height: 38px !important;
        overflow: hidden;
        
        .tag-list {
            display: inline;
            padding: 0;
            margin: 0;

            .tag-item {
                display: inline-block;
                margin: 4px;

                .tag {
                    background-color: #f4f4f4;
                    border: 1px solid #e9e9e9;
                    padding: 2px 0 2px 2px;
                    border-radius: 2px;
                    display: inline-block;

                    button {
                        appearance: button;
                        -webkit-writing-mode: horizontal-tb !important;
                        text-rendering: auto;
                        color: -internal-light-dark(black, white);
                        letter-spacing: normal;
                        word-spacing: normal;
                        text-transform: none;
                        text-indent: 0px;
                        text-shadow: none;
                        display: inline-block;
                        text-align: center;
                        align-items: flex-start;
                        cursor: default;
                        background-color: -internal-light-dark(rgb(239, 239, 239), rgb(59, 59, 59));
                        box-sizing: border-box;
                        margin: 0em;
                        font: 400 13.3333px Arial;
                        padding: 1px 6px;
                        border-width: 2px;
                        // border-style: outset;
                        border-color: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
                        border-image: initial;
                    }
                }                

                .search {
                    border: none;
                    border-bottom: 1px solid #ccc;
                    outline: none;
                }

                input[type="text"i] {
                    padding: 1px 2px;
                }

                input {
                    -webkit-writing-mode: horizontal-tb !important;
                    text-rendering: auto;
                    color: -internal-light-dark(black, white);
                    letter-spacing: normal;
                    word-spacing: normal;
                    text-transform: none;
                    text-indent: 0px;
                    text-shadow: none;
                    display: inline-block;
                    text-align: start;
                    appearance: textfield;
                    background-color: -internal-light-dark(rgb(255, 255, 255), rgb(59, 59, 59));
                    -webkit-rtl-ordering: logical;
                    cursor: text;
                    margin: 0em;
                    font: 400 13.3333px Arial;
                }
            }
            
            .tag-item:last-child {
                margin-right: 4px;
            }
        }

        ul {
            display: block;
            list-style-type: disc;
            margin-block-start: 1em;
            margin-block-end: 1em;
            margin-inline-start: 0px;
            margin-inline-end: 0px;
            padding-inline-start: 40px;
        }
        
        ul,
        ol {
            font-weight: 400;
            letter-spacing: 0;
        }
    }

    .arrow {
        cursor: pointer;
    }

    .bottom:after {
        content: "\25BC";
        vertical-align: middle;
        color: #3c3c3c;
        margin-right: 2px;
        float: right;
    }

    .top:after {
        content: "\25B2";
        vertical-align: middle;
        color: #3c3c3c;
        margin-right: 2px;
        float: right;
    }

    .disabled {
        cursor: not-allowed;

        .bottom:after {
            color: #b9b9b9;
        }
    }

    .dropdown-content {
        position: absolute;
        padding: 4px;
        z-index: 1;
        background: #fff;
        border-top: 1px solid rgba(0, 0, 0, .05);
        -webkit-box-shadow: 0 5px 8px rgba(0, 0, 0, .15);
        box-shadow: 0 5px 8px rgba(0, 0, 0, .15);

        ul {
            margin: 0;
            padding: 0;
        }

        .node {
            list-style: none;
            white-space: nowrap;
            padding: 4px;

            .checkbox-item,
            .radio-item {
                vertical-align: middle;
                margin: 0 4px 0 0
            }

            .checkbox-item.simple-select,
            .radio-item.simple-select {
                display: none
            }
        }

        .node>label {
            cursor: pointer;
            margin-left: 2px;
        }
        .node.leaf.collapsed {
            display: none
        }
        
        .node.disabled>* {
            color: gray;
            cursor: not-allowed
        }
        
        .node.match-in-children.hide .node-label {
            opacity: .5
        }
        
        .node.focused {
            background-color: #f4f4f4
        }

        .tree {
            .toggle {
                white-space: pre;
                margin-right: 4px;
                cursor: pointer
            }
            
            .toggle:after {
                content: " "
            }
            
            .toggle.collapsed:after {
                content: "+"
            }
            
            .toggle.expanded:after {
                content: "-"
            }
        }
    }
}

.tag-remove {
    color: #a0a0a0;
    font-size: 75%;
    line-height: 100%;
    cursor: pointer;
    background-color: transparent;
    border: none;
    outline: none;
}

.searchModeOn .toggle {
    display: none
}

.p-multiselect, .p-dropdown{
    width: 100%;
}


