$black-color-60: rgba(0, 0, 0, 0.6);
$position-absolute: absolute;
$position-relative: relative;
$position-fixed: fixed;
$display-flex: flex;

/* Color */
$color-01: rgb(85, 85, 85);
$color-02: #008ECB;
$color-white: white;
$color-red: red;
$color-grey: grey;

/* Margin */
$margin-auto: auto;

/*  Background color */
$background-blue-color: rgb(0, 142, 203);
$background-color-01: #eaecf4;
$background-color-02: #e9ecef;

/******************************
/* Color system*/

$theme-light: #fff;
$white: #fff !default;
$gray-100: #e9edf2 !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #99abb4 !default;
$gray-700: #525f7f !default;
$gray-800: #263238 !default;
$gray-900: #212529 !default;
$black: #000 !default;
$cyan: #4fc3f7 !default;
$primary: #745af2 !default;
$light-info: #cfecfe !default;
$light-inverse: #f6f6f6 !default;
$success: #06d79c !default;
$danger: #ef5350 !default;
$warning: #ffb22b !default;
$theme-colors: () !default;
$theme-colors: map-merge(('primary': #745af2,
    'secondary': #545b62,
    'success': #06d79c,
    'info': #398bf7,
    'warning': #ffb22b,
    'danger': #ef5350,
    'light': $gray-100,
    'dark': $gray-800,
    'cyan': #4fc3f7,
    'orange': #fb6340,
    'purple': #7460ee,
    'inverse': #2f3d4a,
    'light-info': #cfecfe,
    'light-inverse': #f6f6f6,
    'light-warning': #fff8ec,
    'light-success': #e8fdeb,
    'light-danger': #f9e7eb,
  ),
  $theme-colors);

$brand-colors: () !default;
$brand-colors: map-merge(('facebook': #3b5999,
    'twitter': #1da1f2,
    'googleplus': #dd4b39,
    'instagram': #e4405f,
    'pinterest': #bd081c,
    'youtube': #cd201f,
    'linkedin': #007bb6,
    'slack': #3aaf85,
    'dribbble': #ea4c89,
    'github': #222222),
  $brand-colors);

// Body
$main-body-bg: #fff !default;
$body-bg: #f4f6f9;
$body-color: #67757c !default;

$box-shadow-sm: 0 0.125rem 0.25rem rgba($black, 0.075) !default;
$box-shadow: 0 0.5rem 1rem rgba($black, 0.08) !default;
$box-shadow-lg: 0 1rem 3rem rgba($black, 0.175) !default;

// Style anchor elements.
$link-hover-decoration: none !default;

// Define common padding and border radius sizes and more.
$border-width: 1px !default;
$border-color: rgba(120, 130, 140, 0.13) !default;
$border-radius: 2px !default;
$border-radius-lg: 4px !default;
$border-radius-sm: 1px !default;

// Progress bars
$progress-height: 6px !default;
$progress-bg: $gray-100;

// Tabs
$nav-tabs-link-active-bg: $white !default;

/*Topbar Colors*/

$topbar: $theme-light;
$topbar-height: 70px;
$topbar-navlink-padding: 0px 15px;
$topbar-navlink-font-size: 17px;
$topbar-navlink-height: 70px;
$topbar-navbrand-padding: 0 10px 0 10px;
$topbar-shadow: 1px 0px 7px rgba(0, 0, 0, 0.05);

%topbar-shadow {
  box-shadow: $topbar-shadow;
  -webkit-box-shadow: $topbar-shadow;
  -o-box-shadow: $topbar-shadow;
  -moz-box-shadow: $topbar-shadow;
}

$topbar-mailbox-width: 300px;
$topbar-mailbox-height: 317px;
$topbar-droptitle-bg: url(../images/img5.png) no-repeat;

$errorbg:url(../images/img5.jpg) no-repeat center center #fff;
$sidebarbg: url(../images/img5.png) no-repeat;

/*Sidebar Colors*/
$left-part-width: 260px;
$sidebar: $theme-light;
$sidebar-alt: #e8eff0;
$sidebar-text: #585858;
$sidebar-icons: #fff;
$sidebar-text-dark: #212529;
$sidebar-icons-dark: #4f5467;

$sidebar-width-full: 260px;
$sidebar-width-iconbar: 180px;
$sidebar-width-mini: 65px;

$sidebar-fontsize: 14.5px;

/*Boxed layout width*/

$boxed-width: 1200px;

/*Shadow*/

$shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);