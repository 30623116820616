.table-content-sensor {
    thead {
        th {
            text-align: center;
        }
    }
    tbody {
        td {
            text-align: center;
        }
    }
}