::-webkit-scrollbar-track {
    background: #110d0d;
}

::-webkit-scrollbar-thumb {
    background: #888;
}

.server {
    display: flex;
    /* align-items: center; */
    padding: 10px 0;
    border: 1px solid rgba(81, 92, 71, 0.445);
    /* border-radius: 4px; */
    white-space: nowrap;
    background-color: rgb(239 236 236 / 30%);
    cursor: pointer;
}

.server:hover {
    background-color: rgba(200, 221, 197, 0.404);
}

.server-icon {
    display: inline-block;
    font-size: 2.5rem;
    margin: 0 2rem;
}

.server-details {
    flex: 1;
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
}

.server-details li {
    display: block;
    /* font-size: 1.2rem; */
    line-height: 1.5;
    /* color: #bbbdc0; */
}
/* 
.server-details li:last-child .data {
    font-weight: normal;
    color: rgba(230, 245, 255, .32);
} */
.server-details .data {
    display: block;
    margin: -23px 0 0 0;
    padding: 0 10px 0 0;
    /* font-weight: 600; */
    text-align: right;
    /* color: #c1c6cb; */
}

.server-details .signal {
    color: #2eb35a;
}

.server-details .signal::before {
    content: "";
    display: inline-block;
    width: 0.6rem;
    height: 0.6rem;
    background-color: currentColor;
    border-radius: 50%;
    margin-right: 0.5rem;
    margin-top: -0.2rem;
    vertical-align: middle;
}

.server-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(23rem, 1fr));
    grid-gap: 0.5rem;
}

.server.has-failed {
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
    border-color: #d22c32;
    background-color: rgba(210, 44, 50, 0.096);
    animation: alertblink 2s ease-in-out infinite;
}
.server.has-connecting {
    /* box-shadow: 0px 4px 15px rgba(0, 0, 0, .2); */
    border-color: #d29e2c;
    background-color: rgba(210, 180, 44, 0.096);
    animation: alertblink 2s ease-in-out infinite;
}
.server.has-problem {
    border-color: #353535;
    background-color: rgb(0 0 0 / 20%);
    /* animation: alertblink 2s ease-in-out infinite; */
}

.server.has-failed .server-icon,
.server.has-failed .signal {
    color: #d22c32;
}

.server.has-failed:hover {
    background-color: rgba(210, 44, 50, 0.2);
    animation: none;
}

.server.has-connecting:hover {
    background-color: rgba(210, 180, 44, 0.192);
    animation: none;
}

.server .col-sm-3 {
    position: relative;
}

.notify {
    position: absolute;
    top: 0px;
    left: 5px;
}

.availability {
    position: absolute;
    bottom: 5px;
    left: 5px;
}

table.table-server-add-form tbody tr td,
table.table-server-add-form thead tr th {
    padding: unset;
}

table.table-server-add-form {
    margin-bottom: unset;
}

/* label */
label.form-check {
    margin-bottom: unset;
}

/* .table-sticky-header .table-bordered {
    border-top: none;
} */

.table-sticky-header th {
    position: sticky;
    top: 0;
    background-color: #fff;
    border-top: none;
}

.table-sticky-header th::before {
    position: absolute;
    content: "";
    top: 0;
    left: -2px;
    width: calc(100% + 2px);
    height: 1px;
    background-color: #dee2e6;
}

.table-sticky-header th::after {
    position: absolute;
    content: "";
    bottom: -2px;
    left: -2px;
    width: calc(100% + 2px);
    height: 1px;
    background-color: #dee2e6;
} 
