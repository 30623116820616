.select-customized {
  width: 100%;

  >div {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
}

.select-parent-menu {
  >div {
    z-index: 4;
  }
}


.add-edit-form {
  max-width: 1000px !important;
  display: block;
  height: 100%;
  overflow: hidden;
  max-height: 100%;
  margin: 0 auto;
  top: 50px;

  .modal-content {
    height: 100%;

    form {
      height: 100%;

      .modal-body {
        height: calc(100% - 115px);
        overflow: hidden;
        padding: 0;

        .content {
          height: 100%;
          overflow: scroll;
          padding: 10px 20px;
        }
      }
    }
  }
}